import { atom } from "recoil";

export const DarkThemeState = atom({
  key: "DarkTheme",
  default: true,
});

export const LangState = atom({
  key: "language",
  default: true,
});

export const isSignUpFormState = atom({
  key: "isSignUp",
  default: false,
});

export const isPwChangeFormState = atom({
  key: "isChangePW",
  default: false,
});

export const isBuildTransfer = atom({
  key: "isBuildTransfer",
  default: { complete: true, filesize: 0, sendfile: 0 },
});
export const isAvatarTransfer = atom({
  key: "isAvatarTransfer",
  default: { complete: true, filesize: 0, sendfile: 0 },
});
export const isModelTransfer = atom({
  key: "isModelTransfer",
  default: { complete: true, filesize: 0, sendfile: 0 },
});
export const isRevitTransfer = atom({
  key: "isRevitTransfer",
  default: { complete: true, filesize: 0, sendfile: 0 },
});

export const isAllcomplete = atom({
  key: "isAllCompTransfer",
  default: { complete: true, code_id: 0, msg: "" },
});
