import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { useRecoilState } from "recoil";
import { isPwChangeFormState, isSignUpFormState, PubKey } from "../libs/atoms";
import LoginForm from "../components/Enter/LoginForm";
import SignUpForm from "../components/Enter/SignUpForm";

import { GoogleBtn } from "../components/Enter/GoogleBtn";
import { SiKakaotalk } from "react-icons/si";
import { useEffect, useState } from "react";
import useUser from "../libs/useUser";
import { useNavigate } from "react-router-dom";
import FindPWForm from "../components/Enter/FindPWForm";
// import GoogleLogin from "react-google-login";

export default function Enter() {
  const [isSignUpForm, setSignUpForm] = useRecoilState(isSignUpFormState);
  const [FindPW, setFindPW] = useRecoilState(isPwChangeFormState);
  const kakaoParmas = {
    client_id: process.env.REACT_APP_KAKAO_CLIENT_ID,
    redirect_uri: "https://build.yugy.org/social/kakao",
    response_type: "code",
  };
  const params = new URLSearchParams(kakaoParmas).toString();
  const setsignIn = () => {
    setFindPW(false);
    setSignUpForm((p) => !p);
  };
  const { isLoggedIn, userLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLoading) {
      if (isLoggedIn) {
        navigate("/buildings");
      }
    }
  }, [userLoading, isLoggedIn, isSignUpForm, FindPW]);

  const goMain = () => {
    window.open("https://yugy.org/");
  };

  return (
    <VStack
      width={"100%"}
      height={["auto", "100vh"]}
      justifyContent={"center"}
      bg={"#EFD39E"}
    >
      <Stack
        borderTopLeftRadius={[0, 10]}
        borderBottomLeftRadius={[0, 10]}
        direction={["column", "row"]}
        borderRadius={["0px", "10px"]}
        width={["100%", "600px"]}
        height={["auto", "700px"]}
        bg={"#EAEDDC"}
        border={"1px solid black"}
        spacing={0}
        position={{ md: "relative", sm: "absolute" }}
      >
        <Box
          width={["100%"]}
          px={2}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack
            width={["100%"]}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            minH="20%"
          >
            <Image
              onClick={goMain}
              cursor={"pointer"}
              src={"/image/signin/yugy_logo.png"}
              width={"20%"}
              objectFit={["contain"]}
              mr={4}
              mt={4}
            />
            <Stack>
              <Text color={"#DBB100"} fontWeight={"700"} fontSize={"4rem"}>
                HERE&NOW
              </Text>
              <Text fontSize={"2rem"}>
                {FindPW ? "ID&PW 찾기" : "메타버스 건축가"}
              </Text>
            </Stack>
          </Stack>
          <Stack
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"8px"}
            minH="50%"
            backgroundImage={"url(/image/signin/signin_img.png)"}
            backgroundPosition={"center"}
            backgroundRepeat={"no-repeat"}
            px={2}
            py={2}
          >
            {FindPW ? (
              <FindPWForm />
            ) : isSignUpForm ? (
              <SignUpForm />
            ) : (
              <LoginForm />
            )}
            <Box fontWeight={"700"} display={"flex"}>
              <Text onClick={setsignIn} cursor={"pointer"} fontSize={"1rem"}>
                {isSignUpForm ? "Log In" : "Sign Up"}
              </Text>
              <Text px={2}>/</Text>
              <Text cursor={"pointer"} onClick={() => setFindPW((p) => !p)}>
                비밀번호 재설정
              </Text>
            </Box>
          </Stack>

          <Stack
            w={"100%"}
            minH="20%"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            px={8}
            pb={8}
          >
            <Text>
              {" "}
              소셜 로그인으로 회원 가입을 하셨을 경우 아래 소셜 로그인 버튼을
              눌러 누르세요.
            </Text>
            <Button
              leftIcon={
                <Box fontSize={"1.5rem"} borderRadius="1rem" bg="black">
                  <SiKakaotalk color="yellow" />
                </Box>
              }
              as="a"
              href={`https://kauth.kakao.com/oauth/authorize?${params}`}
              border={"1px solid black"}
              w={"100%"}
              minH={"50px"}
              colorScheme={"gray"}
            >
              Sign in KaKao
            </Button>
            <GoogleBtn />
          </Stack>
        </Box>
      </Stack>
    </VStack>
  );
}
