import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import Cookie from "js-cookie";
import { emailRegEx, passwordRegEx } from "./regExp";
import { dateFormat, fileCalCulator } from "./format";

import { DataHashing } from "./useHash";
const IS_HOSTING = false;

const instance = axios.create({
  baseURL: IS_HOSTING
    ? "https://api.yugy.org/api/v1"
    : "http://127.0.0.1:8000/api/v1",
  //api 요청시 쿠키 보냄
  withCredentials: true,
});

export const getMe = () =>
  instance
    .get(
      `users/me`,
      {},
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const getUnUseablePassword = () =>
  instance
    .get(
      `users/unuseblepw`,
      {},
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const putUpdateUserData = ({
  email,
  name,
  location,
  detailed_location,
  phone_number,
  job,
}) =>
  instance
    .put(
      `users/me`,
      { email, name, location, detailed_location, phone_number, job },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const UpdateAvatar = ({ id, file }) => {
  const userHash = DataHashing(id);
  const { filepath, fileType, fileSize } = fileCalCulator(id, file);
  console.log(userHash, filepath);

  return instance
    .put(
      `users/me/updateavatar`,
      { avatar: filepath, user_hash: userHash },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
};

export const UpdatePasswordEmail = (email) => {
  return instance
    .post(
      `users/pwchage-email`,
      { email },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
};
export const UpdatePasswordEmail2 = ({ email }) => {
  return instance
    .post(
      `users/pwchage-email`,
      { email },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
};
export const UpdatePasswordwithJwT = ({ new_password, token }) => {
  return instance
    .post(
      `users/change-passwordwithjwt`,
      { new_password, token },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
};

export const usernameLogin = ({ username, password }) =>
  instance
    .post(
      "/users/log-in",
      { username, password },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const ConfirmEmail = ({ email }) => {
  const reg = new RegExp(emailRegEx);

  if (reg.test(email)) {
    return instance
      .post(
        "/users/verify",
        { email },
        {
          headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
        }
      )
      .then((response) => response.data);
  } else {
    return {
      code_id: 3006,
      msg: "이메일 형식이 아닙니다.",
    };
  }
};

export const NormalSignUp = ({ email, name, password, repassword }) => {
  const passreg = new RegExp(passwordRegEx);
  if (!passreg.test(password)) {
    return {
      code_id: 3004,
      msg: "비밀번호 형식이 맞지 않습니다.(최소 8자, 특수 문자 1자 포함)",
    };
  }
  if (password === repassword) {
    return instance
      .post(
        "/users/sign-up",
        { email, name, password },
        {
          headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
        }
      )
      .then((response) => response.data);
  } else {
    return {
      code_id: 3005,
      msg: "비밀번호가 다릅니다.",
    };
  }
};

export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const kakaoLogin = (code) =>
  instance
    .post(
      "/users/kakao",
      { code },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.status);

export const apiSignUp = (token) =>
  instance
    .post(
      "/users/verifyemail",
      { token },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const GoogleLogin = (data) =>
  instance
    .post(
      "/users/google",
      { data },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const getUserIdToGetTinyList = ({ queryKey }) => {
  const [_, user_id] = queryKey;
  return instance
    .get(`/buildings/mytinylist`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const getUserIdToGetTinyListbyHost = ({ queryKey }) => {
  const [_, user_id] = queryKey;
  return instance
    .get(`/buildings/mytinylist/admin`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};

//building
export const BuildingNewData = async ({
  id,
  buildImg,
  name,
  modeling,
  revit_data,
  location,
  detailed_location,
  floor_area,
  structure,
  construction_cost,
  en_rating,
  en_saving,
  completion_date,
  attribute,
}) => {
  if (construction_cost === "") construction_cost = 0;
  if (en_rating === "") en_rating = 0;
  if (en_saving === "") en_saving = 0;
  if (completion_date === "") completion_date = dateFormat(new Date());
  let filetype = "";
  let filesize = "";
  let imgUrl = "";
  let fileurl = "";
  let revitUrl = "";

  if (modeling !== "") {
    const { filepath, fileType, fileSize } = fileCalCulator(id, modeling);
    filetype = fileType;
    filesize = fileSize;
    fileurl = filepath;
  }
  if (buildImg !== "") {
    const { filepath, fileType, fileSize } = fileCalCulator(id, buildImg);
    imgUrl = filepath;
  }
  if (revit_data !== "") {
    const { filepath, fileType, fileSize } = fileCalCulator(id, revit_data);
    revitUrl = filepath;
  }
  return instance
    .post(
      `buildings/create`,
      {
        image: imgUrl,
        name,
        modeling: modeling !== "" ? { fileurl, filetype, filesize } : modeling,
        revit_data: revit_data !== "" ? revitUrl : revit_data,
        location,
        detailed_location,
        floor_area,
        structure,
        construction_cost,
        en_rating,
        en_saving,
        completion_date,
        attribute,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
};

export const GetBuildingDatabyBId = ({ queryKey }) => {
  const [_, build_pk] = queryKey;
  return instance
    .get(`/buildings/${build_pk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};

export const PostUpdateBuildingDatabyBId = ({
  id,
  buildImg,
  name,
  modeling,
  revit_data,
  location,
  detailed_location,
  floor_area,
  structure,
  construction_cost,
  en_rating,
  en_saving,
  completion_date,
  attribute,
  build_id,
  is_unity_build,
}) => {
  if (construction_cost === "") construction_cost = 0;
  if (en_rating === "") en_rating = 0;
  if (en_saving === "") en_saving = 0;
  if (completion_date === "") completion_date = dateFormat(new Date());
  let filetype = "";
  let filesize = "";
  let imgUrl = "";
  let fileurl = "";
  let revitUrl = "";

  if (modeling !== "" && modeling.length <= 5) {
    const { filepath, fileType, fileSize } = fileCalCulator(id, modeling);
    filetype = fileType;
    filesize = fileSize;
    fileurl = filepath;
  } else {
    fileurl = modeling;
  }
  if (buildImg !== "" && buildImg.length <= 5) {
    const { filepath, fileType, fileSize } = fileCalCulator(id, buildImg);
    imgUrl = filepath;
  } else {
    imgUrl = buildImg;
  }
  if (revit_data !== "" && revit_data.length <= 5) {
    const { filepath, fileType, fileSize } = fileCalCulator(id, revit_data);
    revitUrl = filepath;
  } else {
    revitUrl = revit_data;
  }
  return instance
    .put(
      `/buildings/${build_id}`,
      {
        image: imgUrl,
        name,
        modeling:
          modeling !== "" && modeling.length <= 5
            ? { fileurl, filetype, filesize }
            : modeling,
        revit_data: revitUrl,
        location,
        detailed_location,
        floor_area,
        structure,
        construction_cost,
        en_rating,
        en_saving,
        completion_date,
        attribute,
        is_unity_build,
      },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
};
