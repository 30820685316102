import urlencode from "urlencode";
import { DataHashing } from "./useHash";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
//파일이 2mb 가 넘는지 확인 하는 함수
export function IsOver2MB(bytes, num = 2, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if (i > 3) {
    //mb로 나눈 값
    const MB = (bytes / Math.pow(k, i)).toFixed(dm);
    return false;
  } else {
    const MB = (bytes / Math.pow(k, i)).toFixed(dm);
    if (MB > num && i === 2) {
      return false;
    }
    return true;
  }
}
export function byteToMB(bytes, num = 2, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  //mb로 나눈 값
  const MB = (bytes / Math.pow(k, 2)).toFixed(dm);
  return MB;
}

//date

export function dateFormat(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;

  return date.getFullYear() + "-" + month + "-" + day;
}
// 날짜 비교

export function compareDateNow(date) {
  if (!date) {
    return true;
  }
  const compareDate = new Date(date);
  const nowdate = Date.now();
  return compareDate >= nowdate;
}

export function fileCalCulator(id, file) {
  const hashedSlice = DataHashing(id);

  let fileType = file[0].type;
  fileType ||= file[0].name.split(".").pop();
  const fileSize = byteToMB(file[0].size);
  const userpath = AwsFilePath(id, file);
  const encodepath = urlencode.encode(userpath);
  const filepath = `https://build-meta.s3.ap-northeast-1.amazonaws.com/${encodepath}`;

  return { filepath, fileType, fileSize };
}

//빈 object 체크

export function isEmptyObj(obj) {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }

  return false;
}

// 파일 루트 변경

export function AwsFilePath(id, file, isUser = false) {
  const nowdate = new Date();
  const now = String(dateFormat(nowdate));
  const isAvatar = isUser ? "avatar" : "building";
  let fileType = file[0].type;
  fileType ||= file[0].name.split(".").pop();
  const hashedSlice = DataHashing(id);
  const hashedSlice2 = DataHashing(now);
  const filepath = `users/${hashedSlice}/${isAvatar}/${hashedSlice2}-${file[0].name}`;

  return filepath;
}
