import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCKtLyuE6-TDJyRwsb51s_ueWm8agevkOQ",
  authDomain: "eminent-glider-213307.firebaseapp.com",
  databaseURL: "https://eminent-glider-213307-default-rtdb.firebaseio.com",
  projectId: "eminent-glider-213307",
  storageBucket: "eminent-glider-213307.appspot.com",
  messagingSenderId: "135223261282",
  appId: "1:135223261282:web:caa7c5b3930ab8625e393b",
  measurementId: "G-7RCTY1KYJM",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
