import { createBrowserRouter } from "react-router-dom";
import NotFound from "./components/Error";
import Root from "./components/Root";
import Enter from "./screens/Enter";
import KakaoConfirm from "./screens/KaKaoConfirm";
import MyBuildings from "./screens/MyBuildings";
import NewBuild from "./screens/NewBuild";
import Profile from "./screens/Profile";
import BuildingUpdate from "./screens/BuildingUpdate";
import EmailVerify from "./screens/EmailVerify";
import ChanePW from "./screens/ChagePW";
import UnityLogin from "./screens/UnityLogin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      { path: "", element: <Enter /> },
      { path: "unitylogin", element: <UnityLogin /> },
      { path: "buildings", element: <MyBuildings /> },
      { path: "profile", element: <Profile /> },
      { path: "buildings/new", element: <NewBuild /> },
      { path: "buildings/:num", element: <BuildingUpdate /> },
      { path: "change-password", element: <ChanePW /> },
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
        ],
      },
      {
        path: "verify",
        children: [
          {
            path: "email",
            element: <EmailVerify />,
          },
        ],
      },
    ],
  },
]);

export default router;
