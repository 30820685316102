import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../libs/useUser";
import {
  getUnUseablePassword,
  UpdatePasswordEmail,
  UpdatePasswordwithJwT,
} from "../libs/api";

import { passwordRegEx } from "../libs/regExp";

export default function ChanePW() {
  const { isLoggedIn, userLoading, user } = useUser();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const isTokenOrNew = async (token, user) => {
    if (!user && !token) {
      toast({
        status: "error",
        title: "권한이 없습니다.",
        description: "error code : 3015",
      });
      queryClient.refetchQueries(["me"]);
      navigate("/");
    } else if (user && !token) {
      const data = await getUnUseablePassword();
      if (data.code_id === 4015) {
        toast({
          status: "error",
          title: "권한이 없습니다.",
          description: "error code : 4015",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/");
      }
    }
  };
  useEffect(() => {
    isTokenOrNew(token, user);
  }, [token, user]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      token: token,
    },
  });
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(UpdatePasswordwithJwT, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2008) {
        toast({
          title: "비밀번호를 변경햇습니다!",
          status: "success",
        });
        navigate("/");
      } else if (data.code_id === 4008) {
        toast({
          title: "비밀번호 변경에 실패했습니다.",
          status: "error",
        });
        resetField("old_password");
      }
      // }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });
  const onSubmitPassword = (new_password, re_new_password, token) => {
    const passreg = new RegExp(passwordRegEx);
    if (new_password !== re_new_password) {
      toast({
        title: "비밀번호가 맞지 않습니다.",
        status: "error",
      });
      resetField("re_new_password");
      return;
    } else if (!passreg.test(new_password)) {
      toast({
        title: "비밀번호 형식이 맞지 않습니다.(최소 8자, 특수 문자 1자 포함)",
        status: "error",
      });
      resetField("re_new_password");
      resetField("new_password");
      return;
    }
    return mutation.mutate({
      new_password,
      token,
    });
  };
  return (
    <VStack width={"100%"} height={["auto", "100vh"]} justifyContent={"center"}>
      <Box
        border={"1px solid black"}
        padding={"2rem"}
        borderRadius={"1rem"}
        bg={"#EAEDDC"}
        as="form"
        onSubmit={handleSubmit(onSubmitPassword)}
      >
        <Box
          w={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={"1rem"}
        >
          <Text fontSize={"2rem"} fontWeight={"700"}>
            Change Password
          </Text>
        </Box>
        <Box
          w={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          mb={"1rem"}
        >
          <Text w={"10%"}></Text>
          <InputGroup
            mb={"1rem"}
            w={"90%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          ></InputGroup>
          <InputGroup
            mb={"1rem"}
            w={"90%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Input
              type="password"
              {...register("new_password", {
                required: { value: true, message: "please input new pw" },
              })}
              bg={"white"}
              name="new_password"
              placeholder="new_password"
            />
            <Input
              type="password"
              {...register("token", {
                required: { value: true, message: "please input token" },
              })}
              bg={"white"}
              display={"none"}
              name="new_password"
              placeholder="new_password"
            />
          </InputGroup>
          <InputGroup
            w={"90%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Input
              type="password"
              {...register("re_new_password", {
                required: { value: true, message: "please input re new pw" },
              })}
              bg={"white"}
              name="re_new_password"
              placeholder="re_new_password"
            />
          </InputGroup>
        </Box>
        <Button
          onClick={() =>
            onSubmitPassword(
              watch("new_password"),
              watch("re_new_password"),
              watch("token")
            )
          }
          colorScheme={"red"}
          w="100%"
        >
          비밀번호 변경
        </Button>
      </Box>
    </VStack>
  );
}
