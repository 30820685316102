import { Heading, VStack } from "@chakra-ui/react";
import CryptoJS from "crypto-js";
import AWS from "aws-sdk";
import { DataHashing } from "./useHash";
import { AwsFilePath, byteToMB } from "./format";
import urlencode from "urlencode";

export default function FileUploadAwsBuilding(id, file, setTransfer, Transfer) {
  const ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY1;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY1;
  const REGION = "ap-northeast-1";
  const S3_BUCKET = "build-meta";
  let fileType = file[0].type;
  fileType ||= file[0].name.split(".").pop();
  const fileSize = byteToMB(file[0].size);
  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    httpOptions: {
      timeout: 60000000,
    },
  });

  const hashedSlice = DataHashing(id);
  const filePath = AwsFilePath(id, file);

  //업로드 버튼 클릭시
  const params = {
    ACL: "public-read",
    ContentType: fileType,
    Body: file[0],
    Bucket: S3_BUCKET,
    Key: filePath,
  };

  myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      setTransfer({
        complete: false,
        filesize: evt.total,
        sendfile: evt.loaded,
      });

      if (evt.loaded === evt.total) {
        setTransfer({ ...Transfer, complete: true });
      }
    })
    .send((err) => {
      if (err) console.log(err);
    });

  return {
    fileUrl: `https://build-meta.s3.ap-northeast-1.amazonaws.com/${filePath}`,
    userHash: hashedSlice,
    fileType,
    fileSize,
  };
}
