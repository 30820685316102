import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { FaLock, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { usernameLogin } from "../../libs/api";

export default function LoginForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(usernameLogin, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2003) {
        toast({
          title: "welcome back!",
          status: "success",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/buildings");
        reset();
      } else if (data.code_id === 4006) {
        toast({
          title: "이메일 인증을 해주세요!",
          status: "error",
        });
      }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });

  const onSubmit = ({ username, password }) => {
    mutation.mutate({
      username,
      password,
    });
  };

  return (
    <Box width={"90%"} as="form" onSubmit={handleSubmit(onSubmit)}>
      <InputGroup mb={"1rem"}>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaUser />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.username?.message)}
          required
          {...register("username", {
            required: "please write username",
          })}
          variant={"filled"}
          placeholder="Username"
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaLock />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.password?.message)}
          required
          type="password"
          {...register("password", {
            required: "please write password",
          })}
          variant={"filled"}
          placeholder="Password"
        />
      </InputGroup>
      <Button
        border={"1px solid black"}
        w={"100%"}
        minH={"50px"}
        colorScheme={"gray"}
        isLoading={mutation.isLoading}
        type="submit"
        mt={4}
      >
        Log in
      </Button>
    </Box>
  );
}
