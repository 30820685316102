import { Heading, Spinner, Text, useToast, VStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { apiSignUp } from "../libs/api";

export default function EmailVerify() {
  const { search } = useLocation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const confirmSignUp = async () => {
    const params = new URLSearchParams(search);
    const token = params.get("token");
    if (token) {
      const data = await apiSignUp(token);
      console.log(`here ${data}`);
      if (data?.code_id === 2011) {
        toast({
          status: "success",
          title: "Welcome!",
          description: "Happy to have you back!",
        });
        navigate("/");
      } else {
        toast({
          status: "error",
          title: "Sorry!",
          description: "Token is Expired!",
        });
        navigate("/");
      }
    }
  };
  useEffect(() => {
    confirmSignUp();
  }, []);
  return (
    <VStack justifyContent={"center"} minH="100vh">
      <Heading>Processing log in...</Heading>
      <Text>Don`t go anywhere.</Text>
      <Spinner size="lg" />
    </VStack>
  );
}
