import styled from "styled-components";

import ProtectedPage from "../components/ProtectedPage";
import useUser from "../libs/useUser";
import {
  Box,
  Button,
  Checkbox,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BuildingNewData,
  GetBuildingDatabyBId,
  PostUpdateBuildingDatabyBId,
} from "../libs/api";
import { useEffect } from "react";
import {
  AiOutlineSearch,
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineDownload,
} from "react-icons/ai";
import BuildFileUploadModal from "../components/mybuilding/BuildFileUploadModal";
import ModelingUploadModal from "../components/mybuilding/ModelingUploadModal";
import RevitUploadModal from "../components/mybuilding/RevitUploadModal";
import FileUploadAwsBuilding from "../libs/FileUploadBuilding";
import { useRecoilState } from "recoil";
import {
  isAllcomplete,
  isBuildTransfer,
  isModelTransfer,
  isRevitTransfer,
} from "../libs/atoms";
import AddressModal from "../components/profile/AddressModal";
import DateModal from "../components/mybuilding/DateModal";
import { useNavigate, useParams } from "react-router-dom";

import { isEmptyObj } from "../libs/format";
import { useState } from "react";
import ProtectedBuilding from "../components/ProtectedBuildingPage";

export default function BuildingUpdate() {
  const { num } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoggedIn, userLoading, user } = useUser();
  const { isLoading, data, isError } = useQuery(
    ["buildingdata", num],
    GetBuildingDatabyBId,
    {
      retry: false,
    }
  );
  ProtectedBuilding(data?.user_id.pk);

  const [BuildTransfer, setBuildTransfer] = useRecoilState(isBuildTransfer);
  const [ModelTransfer, setModelTransfer] = useRecoilState(isModelTransfer);
  const [revitTransfer, setrevitTransfer] = useRecoilState(isRevitTransfer);
  const [Compdata, setCompdata] = useRecoilState(isAllcomplete);
  const [Attr, setAttr] = useState(data?.attributes);
  const [check, setCheck] = useState(data?.is_unity_build);
  const {
    isOpen: isFileImgOpen,
    onClose: onFileImgClose,
    onOpen: onFileImgOpen,
  } = useDisclosure();
  const {
    isOpen: isModelingOpen,
    onClose: onModelingClose,
    onOpen: onModelingOpen,
  } = useDisclosure();
  const {
    isOpen: isrevitOpen,
    onClose: onrevitClose,
    onOpen: onrevitOpen,
  } = useDisclosure();
  const {
    isOpen: isAddressOpen,
    onClose: isAddressClose,
    onOpen: onAdressOpen,
  } = useDisclosure();
  const {
    isOpen: isDateOpen,
    onClose: isDateClose,
    onOpen: onDateOpen,
  } = useDisclosure();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      build_id: num ?? "",
      id: user?.id ?? "",
      buildImg: "",
      modeling: "",
      revit_data: "",
      name: "",
      location: "",
      detailed_location: "",
      structure: "",
      completion_date: "",
      floor_area: 0,
      construction_cost: 0,
      en_rating: 1,
      en_saving: 0,
      is_unity_build: false,
    },
  });

  useEffect(() => {
    if (!isLoading && !isEmptyObj(data)) {
      setValue("buildImg", data?.image);
      setValue("name", data?.name);
      setValue("revit_data", data?.revit_data);
      setValue("modeling", data?.modeling?.fileurl ?? "");
      setValue("location", data?.location);
      setValue("detailed_location", data?.detailed_location);
      setValue("structure", data?.structure);
      setValue("floor_area", Number(data.floor_area) ?? 0);
      setValue("construction_cost", Number(data.construction_cost) ?? 0);
      setValue("en_rating", Number(data.en_rating) ?? 1);
      setValue("en_saving", Number(data.en_saving) ?? 0);
      setValue("completion_date", data?.completion_date ?? "");
      setValue("id", user?.id);
      setValue("attribute", data?.attributes);
      setValue("is_unity_build", data?.is_unity_build);
      setAttr(data?.attributes);
    } else if (!isLoading && isEmptyObj(data)) {
      toast({
        title: "권한이 없습니다.",
        status: "error",
      });
      navigate("/buildings");
    }
  }, [isLoading, isError]);
  useEffect(() => {
    setValue("attribute", Attr);
  }, [isLoading, isError, data, setValue, Attr, setAttr]);

  const mutation = useMutation(PostUpdateBuildingDatabyBId, {
    onMutate: () => {
      if (watch("modeling") !== "" && watch("modeling").length < 3) {
        setModelTransfer({ ...ModelTransfer, complete: false });
        FileUploadAwsBuilding(
          user?.id,
          watch("modeling"),
          setModelTransfer,
          ModelTransfer
        );
      }
      if (watch("buildImg") !== "" && watch("buildImg").length < 3) {
        setBuildTransfer({ ...BuildTransfer, complete: false });
        FileUploadAwsBuilding(
          user?.id,
          watch("buildImg"),
          setBuildTransfer,
          BuildTransfer
        );
      }
      if (watch("revit_data") !== "" && watch("revit_data").length < 3) {
        setrevitTransfer({ ...revitTransfer, complete: false });
        FileUploadAwsBuilding(
          user?.id,
          watch("revit_data"),
          setrevitTransfer,
          revitTransfer
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(["buildingdata", num]);
      setCompdata({ ...Compdata, code_id: data.code_id, msg: data.msg });
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });

  const onSubmitUpdate = ({
    id,
    build_id,
    buildImg,
    name,
    modeling,
    revit_data,
    location,
    detailed_location,
    floor_area,
    structure,
    construction_cost,
    en_rating,
    en_saving,
    completion_date,
    attribute,
    is_unity_build,
  }) => {
    attribute = Attr;
    return mutation.mutate({
      id,
      build_id,
      buildImg,
      name,
      modeling,
      revit_data,
      location,
      detailed_location,
      floor_area,
      structure,
      construction_cost,
      en_rating,
      en_saving,
      completion_date,
      attribute,
      is_unity_build,
    });
  };
  const PlusAttribute = () => {
    setAttr((p) => [...p, { trait_type: "패시브", value: "" }]);
  };
  const MinusAttribute = () => {
    setAttr((p) => {
      if (p.length !== 1) p.pop();
      return [...p];
    });
  };

  const DownLoadFile = (props) => {
    if (!props || props === "") {
      return toast({
        title: "파일이 없습니다.",
        status: "error",
      });
    }
    window.open(props);
  };
  return (
    <ProtectedPage>
      {!isLoading ? (
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmitUpdate)}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          pt={"7rem"}
        >
          <Box minH={"80vh"} width={"80%"}>
            <Text mb={"1rem"} fontWeight={"700"} fontSize={"1.5rem"}>
              건물 정보 변경
            </Text>
            <Box
              p={"2rem 4rem"}
              border={"1px solid #E6E6E6"}
              backgroundColor={"#FFFFFA"}
              borderRadius={"20px"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <Box
                w={"100%"}
                display={user?.is_host ? "flex" : "none"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>유니티 건설 완료</Text>
                <InputGroup
                  w={"90%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Checkbox
                    border={"0.1px solid gray"}
                    {...register("is_unity_build")}
                    onChange={(p) =>
                      setCheck((p) => {
                        setValue("is_unity_build", !p);
                        return !p;
                      })
                    }
                    variant={"filled"}
                    value={data?.is_unity_build}
                    defaultChecked={data?.is_unity_build}
                    name="name"
                    placeholder="name"
                  />
                </InputGroup>
              </Box>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>건물 사진</Text>
                <Input
                  {...register("buildImg")}
                  variant={"filled"}
                  name="name"
                  placeholder="name"
                  display={"none"}
                />
                <Input
                  {...register("build_id", {
                    required: { value: true, message: "please write email" },
                  })}
                  variant={"filled"}
                  name="name"
                  placeholder="name"
                  display={"none"}
                />
                <Input
                  {...register("id", {
                    required: { value: true, message: "please write email" },
                  })}
                  variant={"filled"}
                  name="name"
                  placeholder="name"
                  display={"none"}
                />
                <Image
                  width={"256px"}
                  height={"256px"}
                  border={"1px solid #E6E6E6"}
                  onClick={onFileImgOpen}
                  src={
                    watch("buildImg") !== data?.image &&
                    watch("buildImg") !== ""
                      ? URL.createObjectURL(watch("buildImg")[0])
                      : data?.image === ""
                      ? "/image/file_preview.png"
                      : data?.image
                  }
                />
              </Box>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>건물명</Text>
                <InputGroup
                  w={"90%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Input
                    {...register("name", {
                      required: { value: true, message: "please write email" },
                    })}
                    variant={"filled"}
                    name="name"
                    placeholder="name"
                  />
                </InputGroup>
              </Box>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>건물 모델링 파일</Text>
                <InputGroup w={"90%"}>
                  <Input
                    opacity={"0.9 !important"}
                    isDisabled={true}
                    {...register("modeling", {})}
                    variant={"filled"}
                    name="modeling"
                    value={
                      watch("modeling") !== "" ? watch("modeling")[0].name : ""
                    }
                    placeholder="modeling file"
                  />
                  <InputRightElement
                    p={"4px"}
                    children={
                      <>
                        <Button
                          onClick={onModelingOpen}
                          border={"1px solid black"}
                          p={"0"}
                        >
                          <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                        </Button>
                      </>
                    }
                  />
                </InputGroup>
              </Box>
              <Button
                onClick={() => DownLoadFile(watch("modeling"))}
                border={"1px solid black"}
                mb={"4"}
                justifySelf={"flex-end"}
                alignSelf={"flex-end"}
                display={user?.is_host ? "flex" : "none"}
              >
                건물 모델링 파일 다운로드
              </Button>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>레빗 데이터 연동</Text>
                <InputGroup w={"90%"}>
                  <Input
                    opacity={"0.9 !important"}
                    isDisabled={true}
                    {...register("revit_data")}
                    variant={"filled"}
                    name="revit_data"
                    value={
                      watch("revit_data") !== ""
                        ? watch("revit_data")[0].name
                        : ""
                    }
                    placeholder="revit data"
                  />
                  <InputRightElement
                    p={"4px"}
                    children={
                      <Button
                        onClick={onrevitOpen}
                        border={"1px solid black"}
                        p={"0"}
                      >
                        <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                      </Button>
                    }
                  />
                </InputGroup>
              </Box>
              <Button
                onClick={() => DownLoadFile(watch("revit_data"))}
                border={"1px solid black"}
                mb={"4"}
                justifySelf={"flex-end"}
                alignSelf={"flex-end"}
                display={user?.is_host ? "flex" : "none"}
              >
                레빗 파일 다운로드
              </Button>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>위치</Text>
                <InputGroup w={"90%"}>
                  <Input
                    opacity={"0.9 !important"}
                    isDisabled={true}
                    {...register("location")}
                    variant={"filled"}
                    name="location"
                    placeholder="location"
                  />
                  <InputRightElement
                    p={"4px"}
                    children={
                      <Button
                        onClick={onAdressOpen}
                        border={"1px solid black"}
                        p={"0"}
                      >
                        <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                      </Button>
                    }
                  />
                </InputGroup>
              </Box>

              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}></Text>
                <InputGroup w={"90%"}>
                  <Input
                    {...register("detailed_location")}
                    variant={"filled"}
                    name="detailed_location"
                    placeholder="location"
                  />
                </InputGroup>
              </Box>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>준공일</Text>
                <InputGroup w={"90%"}>
                  <Input
                    isDisabled={true}
                    opacity={"0.9 !important"}
                    {...register("completion_date")}
                    variant={"filled"}
                    name="completion_date"
                    placeholder="Completion Date"
                  />
                  <InputRightElement
                    p={"4px"}
                    children={
                      <Button
                        onClick={onDateOpen}
                        border={"1px solid black"}
                        p={"0"}
                      >
                        <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                      </Button>
                    }
                  />
                </InputGroup>
              </Box>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>구조</Text>
                <InputGroup w={"90%"}>
                  <Input
                    {...register("structure")}
                    variant={"filled"}
                    name="structure"
                    placeholder="Structure"
                  />
                </InputGroup>
              </Box>
              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>연 면적</Text>
                <InputGroup w={"90%"}>
                  <Input
                    {...register("floor_area")}
                    variant={"filled"}
                    name="floor_area"
                    type="number"
                    placeholder="Floor Area ( ㎡ )"
                  />
                </InputGroup>
              </Box>

              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>전체 공사비</Text>
                <InputGroup w={"90%"}>
                  <Input
                    {...register("construction_cost")}
                    variant={"filled"}
                    type="number"
                    name="construction_cost"
                    placeholder="construction_cost"
                  />
                </InputGroup>
              </Box>

              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>에너지 효율 등급</Text>
                <InputGroup w={"90%"}>
                  <Input
                    {...register("en_rating", { max: 5 })}
                    variant={"filled"}
                    type="number"
                    max="5"
                    name="en_rating"
                    placeholder="Energy Rating ( 1 ~ 5 )"
                  />
                </InputGroup>
              </Box>

              <Box
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>에너지 절감률</Text>
                <InputGroup w={"90%"}>
                  <Input
                    border={"none"}
                    {...register("en_saving", { max: 100 })}
                    variant={"filled"}
                    type="number"
                    name="en_saving"
                    max="100"
                    placeholder="Energy Saving ( % )"
                  />
                </InputGroup>
              </Box>
              <Input
                {...register("attribute")}
                variant={"filled"}
                name="attribute"
                display={"none"}
                placeholder="Attribute"
              />
              {Attr?.map((data, i) => (
                <Box
                  key={i}
                  w={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  mb={"1rem"}
                >
                  <Text w={"10%"}>
                    {i === 0 ? "그린 리모델링 적용 기술" : ""}
                  </Text>
                  <InputGroup w={"90%"}>
                    <Input
                      {...register("attribute")}
                      variant={"filled"}
                      name="attribute"
                      display={"none"}
                      placeholder="Attribute"
                    />
                    <Select
                      onChange={(t) => {
                        return setAttr((p) => {
                          p.splice(i, 1, {
                            ...p[i],
                            trait_type: t.target.value,
                          });
                          return p;
                        });
                      }}
                      defaultValue={data.trait_type}
                      variant={"filled"}
                      mr={"2"}
                    >
                      <option value="패시브">패시브</option>
                      <option value="액티브">액티브</option>
                      <option value="신재생">신재생</option>
                      <option value="기타">기타</option>
                    </Select>
                    <Input
                      onChange={(t) =>
                        setAttr((p) => {
                          p.splice(i, 1, {
                            ...p[i],
                            value: t.target.value,
                          });
                          return p;
                        })
                      }
                      defaultValue={data.value}
                      variant={"filled"}
                    />
                  </InputGroup>
                </Box>
              ))}
              <Box
                w="100%"
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
              >
                <Box mb={"0.5rem"} mr={"0.5rem"} textAlign={"center"}>
                  Attribute 추가
                </Box>
                <Button
                  onClick={PlusAttribute}
                  border={"1px solid black"}
                  mr={"0.5rem"}
                  p={"0"}
                >
                  <AiOutlinePlus width={"100%"} fontSize={"1rem"} />
                </Button>
                <Button
                  onClick={MinusAttribute}
                  border={"1px solid black"}
                  p={"0"}
                >
                  <AiOutlineMinus width={"100%"} fontSize={"1rem"} />
                </Button>
              </Box>
              <BuildFileUploadModal
                user={user}
                isOpen={isFileImgOpen}
                onClose={onFileImgClose}
                setValue={setValue}
                header={"Input Build Img"}
              />
              <ModelingUploadModal
                user={user}
                isOpen={isModelingOpen}
                onClose={onModelingClose}
                setValue={setValue}
                header={"Input Modeling File"}
              />
              <RevitUploadModal
                user={user}
                isOpen={isrevitOpen}
                onClose={onrevitClose}
                setValue={setValue}
                header={"Input revit File"}
              />
              <AddressModal
                setValue={setValue}
                isOpen={isAddressOpen}
                onClose={isAddressClose}
              />
              <DateModal
                setValue={setValue}
                isOpen={isDateOpen}
                onClose={isDateClose}
              />
            </Box>
            <Button type="submit" alignSelf={"flex-end"}>
              수정
            </Button>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </ProtectedPage>
  );
}
