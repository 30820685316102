import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaLock, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ConfirmEmail,
  UpdatePasswordEmail,
  usernameLogin,
} from "../../libs/api";
import { isPwChangeFormState } from "../../libs/atoms";
import { emailRegEx } from "../../libs/regExp";

export default function FindPWForm() {
  const [FindPW, setFindPW] = useRecoilState(isPwChangeFormState);
  const [emailVerify, setEmailVerify] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const toast = useToast();
  const queryClient = useQueryClient();

  const Verifymutation = useMutation(ConfirmEmail, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2005) {
        toast({
          title: "가입되지 않은 이메일 입니다.",
          status: "error",
        });
      } else {
        toast({
          title: "가입된 이메일 입니다.",
          status: "success",
        });
        setEmailVerify(true);
      }
    },
    onError: (error) => {
      console.log("사용 할 수 없는 이메일 입니다.");
    },
  });

  const onVerifyEmail = ({ email }) => {
    if (emailVerify) {
      setEmailVerify((p) => !p);
    } else {
      if (email !== "") {
        Verifymutation.mutate({ email });
      }
    }
  };

  const ChangePW = async () => {
    const pwdata = await UpdatePasswordEmail(watch().email);
    if (pwdata?.code_id === 2001) {
      toast({
        title: "비밀번호 변경 메일을 전송 했습니다! ",
        description: "메일 확인 후 비밀번호를 변경해주세요.",
        status: "success",
      });
      queryClient.refetchQueries(["me"]);
      setFindPW(false);
    } else {
      toast({
        title: "비밀번호 변경 에러",
        status: "error",
      });
    }
  };

  return (
    <Box width={"90%"}>
      <InputGroup mb={"1rem"}>
        <Input
          isDisabled={emailVerify}
          {...register("email", {
            required: { value: true, message: "please write email" },
            pattern: { value: emailRegEx, message: "Email is not valid." },
          })}
          variant={"filled"}
          name="email"
          placeholder="email"
        />
        <InputRightElement
          children={
            <Box
              onClick={() => onVerifyEmail({ email: watch().email })}
              border={"1px solid black"}
            >
              <Button>{emailVerify ? "이메일 변경" : "이메일 확인"}</Button>
            </Box>
          }
        />
      </InputGroup>

      <Button
        border={"1px solid black"}
        w={"100%"}
        minH={"50px"}
        colorScheme={"gray"}
        onClick={ChangePW}
        isLoading={Verifymutation.isLoading}
        type="button"
        mt={4}
      >
        비밀번호 재설정
      </Button>
    </Box>
  );
}
