import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Calendar, { CalendarTileProperties, Detail } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { dateFormat } from "../../libs/format";

export default function DateModal({ setValue, isOpen, onClose }) {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Calendar
              onChange={(p) => {
                setValue("completion_date", dateFormat(p));
                onClose();
              }}
              locale="ko-KO"
              prev2Label={null}
              next2Label={null}
              minDetail="decade"
              maxDate={new Date(Date.now() + 60 * 60 * 24 * 7 * 4 * 6 * 1000)}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
