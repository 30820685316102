import { Box, Button, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import useUser from "../libs/useUser";
import { useEffect } from "react";

export default function UnityLogin() {
  const { userLoading, isLoggedIn, user } = useUser();

  useEffect(() => {
    if (!userLoading) {
      window.open(`YugyNowMetaverseFile.myp://${user?.email}`, "_blank");
    }
  }, [userLoading, user]);
  const WindowClick = () => {
    window.innerWidth > 500
      ? (window.location.href =
          "https://metaaxel.s3.us-west-1.amazonaws.com/mysetup.zip")
      : alert(
          "PC 환경에서 다운로드 가능 합니다. pc 환경이라면 창을 키워주세요."
        );
  };

  return (
    <VStack justifyContent={"center"} minH="100vh">
      <Image w="57px" h="57px" src={"/image/metainfo_icon.png"} />
      <Text
        p={"1rem "}
        fontWeight={"600"}
        color={"#707070"}
        textAlign={"center"}
        fontSize={"2rem"}
      >
        메타버스 클라이언트를 설치하지 않으신 분들은 아래 다운로드 버튼을
        클릭하여 <br /> 최신버전의 프로그램을 설치하시기 바랍니다.
      </Text>
      <Box colorScheme={"blackAlpha"}>
        <Image
          onClick={WindowClick}
          w="323px"
          h="119px"
          src={"/image/metaaxel_btn.png"}
        />
      </Box>
    </VStack>
  );
}
