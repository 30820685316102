import { useToast } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../libs/useUser";

export default function ProtectedBuilding(building_id) {
  const { isLoggedIn, userLoading, user } = useUser();
  const toast = useToast();
  const navigate = useNavigate();
  if (user?.is_host) return;
  if (user !== undefined && building_id !== undefined) {
    if (user?.id !== building_id) {
      toast({
        title: "건물 정보를 수정 할 권한이 없습니다.",
        status: "error",
      });
      navigate("/buildings");
    }
  }
}
