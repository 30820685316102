import { Box, Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "../../libs/api";
import { auth } from "../../libs/firebase";
import { FcGoogle } from "react-icons/fc";

export const GoogleBtn = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(GoogleLogin, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2003) {
        toast({
          status: "success",
          title: "Welcome!",
          description: "Happy to have you back!",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/buildings");
      } else if (data.code_id === 2006) {
        toast({
          status: "success",
          title: "Welcome! Your First Log In",
          description: "비밀번호를 설정 해주세요.",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/change-password");
      }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });
  function handleGoogleLogin() {
    const provider = new GoogleAuthProvider(); // provider 구글 설정
    signInWithPopup(auth, provider) // 팝업창 띄워서 로그인
      .then((data) => {
        mutation.mutate(data.user); // user data 설정
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Button
      leftIcon={
        <Box fontSize={"1.5rem"} borderRadius="50%" bg="white">
          <FcGoogle />
        </Box>
      }
      onClick={handleGoogleLogin}
      border={"1px solid black"}
      w={"100%"}
      minH={"50px"}
      colorScheme={"gray"}
    >
      Sign in Google
    </Button>
  );
};
