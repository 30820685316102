import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UpdateAvatar, UpdatePasswordEmail } from "../../libs/api";
import { IsOver2MB } from "../../libs/format";
import { passwordRegEx } from "../../libs/regExp";

export default function PasswordModal({
  user,
  isOpen,
  onClose,
  header = "Password Update",
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      id: user?.id,
    },
  });
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(UpdatePasswordEmail, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2008) {
        toast({
          title: "비밀번호를 변경햇습니다.!",
          status: "success",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/");
        onClose();
      } else if (data.code_id === 4008) {
        toast({
          title: "비밀번호 변경에 실패했습니다.",
          status: "error",
        });
        resetField("old_password");
      }
      // }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });
  const onSubmitPassword = (old_password, new_password, re_new_password) => {
    const passreg = new RegExp(passwordRegEx);
    if (new_password !== re_new_password) {
      toast({
        title: "비밀번호가 맞지 않습니다.",
        status: "error",
      });
      resetField("re_new_password");
      return;
    } else if (!passreg.test(new_password)) {
      toast({
        title: "비밀번호 형식이 맞지 않습니다.(최소 8자, 특수 문자 1자 포함)",
        status: "error",
      });
      resetField("re_new_password");
      resetField("new_password");
    }
    mutation.mutate({
      old_password,
      new_password,
    });
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmitPassword)}>
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            mb={"1rem"}
          >
            <Text w={"10%"}>{""}</Text>
          </Box>
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            mb={"1rem"}
          >
            <Text w={"10%"}></Text>
            <InputGroup
              mb={"1rem"}
              w={"90%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                type="password"
                {...register("old_password", {
                  required: { value: true, message: "please input Img" },
                })}
                name="old_password"
                placeholder="old_password"
              />

              <Input
                display={"none"}
                {...register("id", {
                  required: { value: true, message: "please write email" },
                })}
                name="id"
                placeholder="id"
              />
            </InputGroup>
            <InputGroup
              mb={"1rem"}
              w={"90%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                type="password"
                {...register("new_password", {
                  required: { value: true, message: "please input Img" },
                })}
                name="new_password"
                placeholder="new_password"
              />
            </InputGroup>
            <InputGroup
              w={"90%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                type="password"
                {...register("re_new_password", {
                  required: { value: true, message: "please input Img" },
                })}
                name="re_new_password"
                placeholder="re_new_password"
              />
            </InputGroup>
          </Box>
          <Button
            onClick={() =>
              onSubmitPassword(
                watch("old_password"),
                watch("new_password"),
                watch("re_new_password")
              )
            }
            colorScheme={"red"}
            w="100%"
          >
            비밀번호 변경
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
