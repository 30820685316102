import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import media from "../libs/media";
import ProtectedPage from "../components/ProtectedPage";
import {
  Box,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import useUser from "../libs/useUser";
import {
  getUserIdToGetTinyListbyHost,
  getUserIdToGetTinyList,
} from "../libs/api";
import { useNavigate } from "react-router-dom";
import BuildingTableTemplate from "../components/mybuilding/BuildingTableTemplate";
import NoItem from "../components/mybuilding/NoItem";
import { set } from "react-hook-form";

export default function MyBuildings() {
  const [now, setNow] = useState(1);
  const page = 10;
  let pageLang = [];
  const { isLoggedIn, userLoading, user: userData } = useUser();
  const { isLoading, data } = useQuery(
    [`building`, isLoggedIn ? userData?.id : ""],
    userData?.is_host ? getUserIdToGetTinyListbyHost : getUserIdToGetTinyList
  );
  const navigate = useNavigate();

  const sortingdata = data?.sort(function (a, b) {
    if (a.pk > b.pk) {
      return -1;
    }
    if (a.pk < b.pk) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });

  // const datalang = Math.ceil(sortingdata.length / page);

  // for (var i = 1; i <= datalang; i++) {
  //   pageLang.push(i);
  // }
  // console.log(datalang);

  // const slice = sortingdata.slice((now - 1) * page, now * page);

  return (
    <ProtectedPage>
      <VStack justifyContent={"flex-start"} minH="100vh">
        <TableContainer mt={"7rem"} mb={"10rem"} w={"80%"}>
          <Table size="lg">
            <Thead backgroundColor={"#EAEDDC"}>
              <Tr border={"1px solid #E6E6E6"}>
                <Th textAlign={"center"}>순번</Th>
                <Th textAlign={"center"}>건물사진</Th>
                <Th textAlign={"center"}>건물명</Th>
                <Th textAlign={"center"}>파일 명</Th>
                <Th textAlign={"center"}>파일 종류</Th>
                <Th textAlign={"center"}>파일 크기</Th>
                <Th textAlign={"center"}>생성일</Th>
                <Th textAlign={"center"}>건설 현황</Th>
                {userData?.is_host ? <Th textAlign={"center"}>빌더</Th> : <></>}
              </Tr>
            </Thead>
            <Tbody>
              {data?.length !== 0 ? (
                sortingdata?.map((props, i) => (
                  <BuildingTableTemplate
                    key={i}
                    data={props}
                    i={i}
                    is_host={userData?.is_host}
                  />
                ))
              ) : (
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td
                    verticalAlign={"middle"}
                    textAlign={"left"}
                    height={"64px"}
                    color={"gray.400"}
                    fontSize="2rem"
                    fontWeight={"700"}
                  >
                    No Item
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          p={"3rem"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {pageLang.map((data, i) => (
            <Text onClick={() => setNow(data)} mr={"1rem"} key={i}>
              {data}
            </Text>
          ))}
        </Box>
      </VStack>
    </ProtectedPage>
  );
}
