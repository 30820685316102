import { Image, Td, Tr } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import urlencode from "urlencode";
import { compareDateNow, dateFormat } from "../../libs/format";

export default function BuildingTableTemplate({ i, data, is_host }) {
  const navigate = useNavigate();
  const UpdateBuilding = (pk) => {
    navigate(`/buildings/${pk}`);
  };
  const buildComplete = compareDateNow(data?.completion_date ?? false);
  const filename = data.modeling?.fileurl.split("/").pop().split("-").pop();
  let decodfilename = "";
  if (filename) decodfilename = urlencode.decode(filename);

  return (
    <Tr key={i} border={"1px solid #E6E6E6"}>
      <Td verticalAlign={"middle"} textAlign={"center"} height={"64px"}>
        {is_host ? data?.pk : i + 1}
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        <Image
          width={"64px"}
          height={"64px"}
          src={data?.image === "" ? "/image/file_preview.png" : data?.image}
        />
      </Td>
      <Td
        verticalAlign={"middle"}
        cursor={"pointer"}
        onClick={() => UpdateBuilding(data?.pk)}
        textAlign={"center"}
      >
        {data?.name}
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        {(decodfilename ??= "")}
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        {data?.modeling?.filetype ?? ""}
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        {data?.modeling?.filesize ?? 0}MB
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        {data?.completion_date}
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        {data?.is_unity_build ? "건설 완료" : "건설 중"}
      </Td>
      <Td verticalAlign={"middle"} textAlign={"center"}>
        {is_host ? data?.user_id?.username : ""}
      </Td>
    </Tr>
  );
}
