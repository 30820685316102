import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ProtectedPage from "../components/ProtectedPage";
import useUser from "../libs/useUser";
import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdEmail, MdOutlineEmail } from "react-icons/md";
import { AiOutlinePhone, AiFillPhone, AiOutlineSearch } from "react-icons/ai";
import { FaUserTie } from "react-icons/fa";
import {
  HiLocationMarker,
  HiOutlineLocationMarker,
  HiUser,
  HiOutlineUser,
} from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  putUpdateUserAvatar,
  putUpdateUserData,
  UpdatePasswordEmail2,
} from "../libs/api";
import CryptoJS from "crypto-js";
import AddressModal from "../components/profile/AddressModal";
import FileUploadModal from "../components/profile/FileUploadModal";
import PasswordModal from "../components/profile/PasswordModal";

export default function Profile() {
  const navigate = useNavigate();
  const { isLoggedIn, userLoading, user } = useUser();
  const queryClient = useQueryClient();

  const {
    isOpen: isAvatarOpen,
    onClose: onAvatarClose,
    onOpen: onAvatarOpen,
  } = useDisclosure();
  const {
    isOpen: isAddressOpen,
    onClose: isAddressClose,
    onOpen: onAddressOpen,
  } = useDisclosure();
  const {
    isOpen: isPasswordOpen,
    onClose: isPasswordClose,
    onOpen: onPasswordOpen,
  } = useDisclosure();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      avatar: user?.avatar ?? "",
      email: user?.email ?? "",
      name: user?.name ?? "",
      location: user?.location ?? "",
      detailed_location: user?.detailed_location ?? "",
      phone_number: user?.phone_number ?? "",
      job: user?.job ?? "",
    },
  });

  const toast = useToast();

  const ChangePW = async () => {
    const pwdata = await UpdatePasswordEmail2(user?.email);
    if (pwdata?.code_id === 2001) {
      toast({
        title: "비밀번호 변경 메일을 전송 했습니다! ",
        description: "메일 확인 후 비밀번호를 변경해주세요.",
        status: "success",
      });
      queryClient.refetchQueries(["me"]);
    } else {
      toast({
        title: "비밀번호 변경 에러",
        status: "error",
      });
    }
  };

  const mutation = useMutation(putUpdateUserData, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2007) {
        toast({
          title: "update your profile!",
          status: "success",
        });
        reset();
      } else if (data.code_id === 3005) {
        toast({
          title: data.msg,
          status: "error",
        });
        resetField("repassword");
      } else if (data.code_id === 3004) {
        toast({
          title: data.msg,
          status: "error",
        });
        resetField("password");
        resetField("repassword");
      }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });
  const onSubmit = ({
    avatar,
    email,
    name,
    location,
    detailed_location,
    phone_number,
    job,
  }) => {
    mutation.mutate({
      avatar,
      email,
      name,
      location,
      detailed_location,
      phone_number,
      job,
    });
  };
  return (
    <ProtectedPage>
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        pt={"7rem"}
      >
        <Box
          minH={"80vh"}
          width={"80%"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Text fontWeight={"700"} fontSize={"1.5rem"}>
            회원 정보
          </Text>
          <Box
            my={"1rem"}
            p={"2rem"}
            px={"4rem"}
            border={"1px solid #E6E6E6"}
            backgroundColor={"#FFFFFA"}
            borderRadius={"20px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>프로필</Text>
              <Image
                onClick={onAvatarOpen}
                width={"256px"}
                height={"256px"}
                border={"1px solid #E6E6E6"}
                src={
                  user?.avatar === "" ? "/image/file_preview.png" : user?.avatar
                }
              />
            </Box>

            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>이메일</Text>
              <InputGroup
                w={"90%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <InputLeftElement
                  children={
                    <Box>
                      {watch()?.email !== "" ? <MdEmail /> : <MdOutlineEmail />}
                    </Box>
                  }
                />
                <Input
                  {...register("email", {
                    required: { value: true, message: "please write email" },
                  })}
                  isDisabled={true}
                  opacity={"0.9 !important"}
                  variant={"filled"}
                  name="email"
                  placeholder="email"
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>이름</Text>
              <InputGroup w={"90%"}>
                <InputLeftElement
                  children={
                    <Box>
                      {watch()?.name !== "" ? <HiUser /> : <HiOutlineUser />}
                    </Box>
                  }
                />
                <Input
                  {...register("name", {
                    required: { value: true, message: "please write email" },
                  })}
                  variant={"filled"}
                  name="name"
                  placeholder="name"
                />
                <InputRightElement
                  justifyContent={"flex-end"}
                  p={"4px"}
                  width={"20%"}
                  children={
                    <Button
                      onClick={ChangePW}
                      border={"1px solid black"}
                      p={"1"}
                    >
                      비밀번호 변경
                    </Button>
                  }
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>지역</Text>
              <InputGroup w={"90%"}>
                <InputLeftElement
                  children={
                    <Box>
                      {watch()?.location ||
                      watch()?.detailed_location !== "" ? (
                        <HiLocationMarker />
                      ) : (
                        <HiOutlineLocationMarker />
                      )}
                    </Box>
                  }
                />
                <Input
                  {...register("location")}
                  variant={"filled"}
                  name="location"
                  opacity={"0.9 !important"}
                  isDisabled={true}
                  placeholder="Location"
                />
                <InputRightElement
                  p={"4px"}
                  children={
                    <Button
                      onClick={onAddressOpen}
                      border={"1px solid black"}
                      p={"0"}
                    >
                      <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                    </Button>
                  }
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}></Text>
              <InputGroup w={"90%"}>
                <InputLeftElement children={<Box></Box>} />
                <Input
                  {...register("detailed_location")}
                  variant={"filled"}
                  name="detailed_location"
                  placeholder="Detail location"
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>전화 번호</Text>
              <InputGroup w={"90%"}>
                <InputLeftElement
                  children={
                    <Box>
                      {watch()?.phone_number !== "" ? (
                        <AiFillPhone />
                      ) : (
                        <AiOutlinePhone />
                      )}
                    </Box>
                  }
                />
                <Input
                  {...register("phone_number")}
                  variant={"filled"}
                  name="phone_number"
                  placeholder="phone_number"
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>직업</Text>
              <InputGroup w={"90%"}>
                <InputLeftElement
                  children={
                    <Box>
                      {watch()?.job !== "" ? <FaUserTie /> : <HiOutlineUser />}
                    </Box>
                  }
                />
                <Input
                  {...register("job")}
                  variant={"filled"}
                  name="job"
                  placeholder="job"
                />
              </InputGroup>
            </Box>
          </Box>
          <Button type="submit" alignSelf={"flex-end"}>
            저장
          </Button>
        </Box>
        <FileUploadModal
          user={user}
          isOpen={isAvatarOpen}
          onClose={onAvatarClose}
          isAvatar={true}
        />
        <AddressModal
          setValue={setValue}
          isOpen={isAddressOpen}
          onClose={isAddressClose}
        />
        <PasswordModal
          setValue={setValue}
          isOpen={isPasswordOpen}
          onClose={isPasswordClose}
        />
      </Box>
    </ProtectedPage>
  );
}
