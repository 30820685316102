import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { UpdateAvatar } from "../../libs/api";
import { isAvatarTransfer } from "../../libs/atoms";
import FileUploadAwsBuilding from "../../libs/FileUploadBuilding";
import { IsOver2MB } from "../../libs/format";

export default function FileUploadModal({
  user,
  isOpen,
  onClose,
  isAvatar = false,
  header = "Update Avatar Img",
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      id: user?.id,
    },
  });
  const [AvatarTransfer, setAvatarTransfer] = useRecoilState(isAvatarTransfer);
  const toast = useToast();
  const queryClient = useQueryClient();

  const [filePreview, setFilePreview] = useState(isAvatar ? user.avatar : "");
  const file = watch("file");
  // console.log(watch());
  useEffect(() => {
    if (file && file.length > 0) {
      const InputFile = file[0];
      const fileExt = InputFile.name.split(".").pop();
      const isOver = IsOver2MB(file[0].size);
      if (
        InputFile.type === "image/jpeg" ||
        fileExt === "jpg" ||
        InputFile.type === "image/png" ||
        fileExt === "png"
      ) {
        if (isOver) {
          setFilePreview(URL.createObjectURL(InputFile));
        } else {
          toast({
            title: "파일 크기가 2mb 보다 큽니다.",
            status: "error",
          });
          reset();
          setFilePreview(isAvatar ? user.avatar : "");
        }
      } else {
        toast({
          title: "jpg 또는 png 파일만 가능합니다.",
          status: "error",
        });
        return;
      }
    }
  }, [file, toast, user?.avatar]);

  const mutation = useMutation(UpdateAvatar, {
    onMutate: () => {
      FileUploadAwsBuilding(
        user?.id,
        watch("file"),
        setAvatarTransfer,
        AvatarTransfer
      );
    },
    onSuccess: (data) => {
      if (data.code_id === 2011) {
        toast({
          title: "update your profile!",
          status: "success",
        });
        queryClient.refetchQueries(["me"]);
        onClose();
      } else {
        toast({
          title: "프로필 변경에 실패했습니다",
          status: "error",
        });
        reset();
      }
      // }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });
  const onSubmitFile = ({ file, id }) => {
    mutation.mutate({
      file,
      id,
    });
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmitFile)}>
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            mb={"1rem"}
          >
            <Text w={"10%"}>{isAvatar ? "프로필" : ""}</Text>
            <Image
              width={"256px"}
              height={"256px"}
              src={filePreview !== "" ? filePreview : "/image/file_preview.png"}
            />
          </Box>
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            mb={"1rem"}
          >
            <Text w={"10%"}></Text>
            <InputGroup
              w={"90%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                type="file"
                {...register("file", {
                  required: { value: true, message: "please input Img" },
                })}
                filename={
                  isAvatar
                    ? user?.avatar ?? "/image/file_preview.png"
                    : "/image/file_preview.png"
                }
                accept="image/png, image/jpeg"
                name="file"
                placeholder="file"
              />
              <Input
                display={"none"}
                {...register("id", {
                  required: { value: true, message: "please write email" },
                })}
                name="id"
                placeholder="id"
              />
            </InputGroup>
          </Box>
          <Button type="submit" colorScheme={"red"} w="100%">
            Update
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
