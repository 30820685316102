import styled from "styled-components";

import ProtectedPage from "../components/ProtectedPage";
import useUser from "../libs/useUser";
import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { BuildingNewData } from "../libs/api";
import { useEffect } from "react";
import { AiOutlineSearch, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import BuildFileUploadModal from "../components/mybuilding/BuildFileUploadModal";
import ModelingUploadModal from "../components/mybuilding/ModelingUploadModal";
import RevitUploadModal from "../components/mybuilding/RevitUploadModal";
import FileUploadAwsBuilding from "../libs/FileUploadBuilding";
import { useRecoilState } from "recoil";
import {
  isAllcomplete,
  isBuildTransfer,
  isModelTransfer,
  isRevitTransfer,
} from "../libs/atoms";
import AddressModal from "../components/profile/AddressModal";
import DateModal from "../components/mybuilding/DateModal";
import { useState } from "react";

export default function NewBuild() {
  const { isLoggedIn, userLoading, user } = useUser();
  const [BuildTransfer, setBuildTransfer] = useRecoilState(isBuildTransfer);
  const [ModelTransfer, setModelTransfer] = useRecoilState(isModelTransfer);
  const [ravitTransfer, setravitTransfer] = useRecoilState(isRevitTransfer);
  const [Compdata, setCompdata] = useRecoilState(isAllcomplete);
  const [Attr, setAttr] = useState([{ trait_type: "패시브", value: "" }]);
  const {
    isOpen: isFileImgOpen,
    onClose: onFileImgClose,
    onOpen: onFileImgOpen,
  } = useDisclosure();
  const {
    isOpen: isModelingOpen,
    onClose: onModelingClose,
    onOpen: onModelingOpen,
  } = useDisclosure();
  const {
    isOpen: isrevitOpen,
    onClose: onrevitClose,
    onOpen: onrevitOpen,
  } = useDisclosure();
  const {
    isOpen: isAddressOpen,
    onClose: isAddressClose,
    onOpen: onAdressOpen,
  } = useDisclosure();
  const {
    isOpen: isDateOpen,
    onClose: isDateClose,
    onOpen: onDateOpen,
  } = useDisclosure();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      id: user?.id ?? "",
      buildImg: "",
      modeling: "",
      revit_data: "",
    },
  });

  useEffect(() => {
    setValue("id", user?.id);
    setValue("attribute", Attr);
  }, [user, setValue, Attr, watch, setAttr]);

  const toast = useToast();

  const mutation = useMutation(BuildingNewData, {
    onMutate: () => {
      if (watch("modeling") !== "") {
        setModelTransfer({ ...ModelTransfer, complete: false });
        FileUploadAwsBuilding(
          user?.id,
          watch("modeling"),
          setModelTransfer,
          ModelTransfer
        );
      }
      if (watch("buildImg") !== "") {
        setBuildTransfer({ ...BuildTransfer, complete: false });
        FileUploadAwsBuilding(
          user?.id,
          watch("buildImg"),
          setBuildTransfer,
          BuildTransfer
        );
      }
      if (watch("revit_data") !== "") {
        setravitTransfer({ ...ravitTransfer, complete: false });
        FileUploadAwsBuilding(
          user?.id,
          watch("revit_data"),
          setravitTransfer,
          ravitTransfer
        );
      }
    },
    onSuccess: (data) => {
      setCompdata({ ...Compdata, code_id: data.code_id, msg: data.msg });
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });
  const onSubmit = ({
    id,
    buildImg,
    name,
    modeling,
    revit_data,
    location,
    detailed_location,
    floor_area,
    structure,
    construction_cost,
    en_rating,
    en_saving,
    completion_date,
    attribute,
  }) => {
    attribute = Attr;

    mutation.mutate({
      id,
      buildImg,
      name,
      modeling,
      revit_data,
      location,
      detailed_location,
      floor_area,
      structure,
      construction_cost,
      en_rating,
      en_saving,
      completion_date,
      attribute,
    });
  };

  const PlusAttribute = () => {
    setAttr((p) => [...p, { trait_type: "패시브", value: "" }]);
  };
  const MinusAttribute = () => {
    setAttr((p) => {
      if (p.length !== 1) p.pop();
      return [...p];
    });
  };
  return (
    <ProtectedPage>
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        pt={"7rem"}
      >
        <Box minH={"80vh"} width={"80%"}>
          <Text mb={"1rem"} fontWeight={"700"} fontSize={"1.5rem"}>
            새 건설
          </Text>
          <Box
            p={"2rem 4rem"}
            border={"1px solid #E6E6E6"}
            backgroundColor={"#FFFFFA"}
            borderRadius={"20px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>건물 사진</Text>
              <Input
                {...register("buildImg")}
                variant={"filled"}
                name="name"
                placeholder="name"
                display={"none"}
              />
              <Input
                {...register("id", {
                  required: { value: true, message: "please write email" },
                })}
                variant={"filled"}
                name="name"
                placeholder="name"
                display={"none"}
              />
              <Image
                width={"256px"}
                height={"256px"}
                border={"1px solid #E6E6E6"}
                onClick={onFileImgOpen}
                src={
                  watch("buildImg") !== ""
                    ? URL.createObjectURL(watch("buildImg")[0])
                    : "/image/file_preview.png"
                }
              />
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>건물명</Text>
              <InputGroup
                w={"90%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Input
                  {...register("name", {
                    required: { value: true, message: "please write email" },
                  })}
                  variant={"filled"}
                  name="name"
                  placeholder="name"
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>건물 모델링 파일</Text>
              <InputGroup w={"90%"}>
                <Input
                  opacity={"0.9 !important"}
                  isDisabled={true}
                  {...register("modeling", {})}
                  variant={"filled"}
                  name="modeling"
                  value={
                    watch("modeling") !== "" ? watch("modeling")[0].name : ""
                  }
                  placeholder="modeling file"
                />
                <InputRightElement
                  p={"4px"}
                  children={
                    <Button
                      onClick={onModelingOpen}
                      border={"1px solid black"}
                      p={"0"}
                    >
                      <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                    </Button>
                  }
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>레빗 데이터 연동</Text>
              <InputGroup w={"90%"}>
                <Input
                  opacity={"0.9 !important"}
                  isDisabled={true}
                  {...register("revit_data")}
                  variant={"filled"}
                  name="revit_data"
                  value={
                    watch("revit_data") !== ""
                      ? watch("revit_data")[0].name
                      : ""
                  }
                  placeholder="Revit data"
                />
                <InputRightElement
                  p={"4px"}
                  children={
                    <Button
                      onClick={onrevitOpen}
                      border={"1px solid black"}
                      p={"0"}
                    >
                      <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                    </Button>
                  }
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>위치</Text>
              <InputGroup w={"90%"}>
                <Input
                  opacity={"0.9 !important"}
                  isDisabled={true}
                  {...register("location")}
                  variant={"filled"}
                  name="location"
                  placeholder="location"
                />
                <InputRightElement
                  p={"4px"}
                  children={
                    <Button
                      onClick={onAdressOpen}
                      border={"1px solid black"}
                      p={"0"}
                    >
                      <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                    </Button>
                  }
                />
              </InputGroup>
            </Box>

            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}></Text>
              <InputGroup w={"90%"}>
                <Input
                  {...register("detailed_location")}
                  variant={"filled"}
                  name="detailed_location"
                  placeholder="location"
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>준공일</Text>
              <InputGroup w={"90%"}>
                <Input
                  isDisabled={true}
                  opacity={"0.9 !important"}
                  {...register("completion_date")}
                  variant={"filled"}
                  name="completion_date"
                  placeholder="Completion Date"
                />
                <InputRightElement
                  p={"4px"}
                  children={
                    <Button
                      onClick={onDateOpen}
                      border={"1px solid black"}
                      p={"0"}
                    >
                      <AiOutlineSearch width={"100%"} fontSize={"1rem"} />
                    </Button>
                  }
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>구조</Text>
              <InputGroup w={"90%"}>
                <Input
                  {...register("structure")}
                  variant={"filled"}
                  name="structure"
                  placeholder="Structure"
                />
              </InputGroup>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>연 면적</Text>
              <NumberInput w={"80%"} backgroundColor={"#E5EAF0"}>
                <NumberInputField
                  border={"none"}
                  {...register("floor_area")}
                  variant={"filled"}
                  name="floor_area"
                  placeholder="Floor Area ( ㎡ )"
                />
              </NumberInput>
            </Box>
            <Box
              w={"90%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>전체 공사비</Text>
              <NumberInput w={"90%"} ml={"1rem"} backgroundColor={"#E5EAF0"}>
                <NumberInputField
                  border={"none"}
                  {...register("construction_cost")}
                  variant={"filled"}
                  name="construction_cost"
                  placeholder="Construction cost"
                />
              </NumberInput>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>에너지 효율 등급</Text>
              <NumberInput
                min={1}
                max={5}
                w={"80%"}
                backgroundColor={"#E5EAF0"}
              >
                <NumberInputField
                  border={"none"}
                  {...register("en_rating")}
                  variant={"filled"}
                  name="en_rating"
                  placeholder="Energy Rating ( 1 ~ 5 )"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
            <Box
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mb={"1rem"}
            >
              <Text w={"10%"}>에너지 절감률</Text>
              <NumberInput
                min={0}
                max={100}
                w={"80%"}
                backgroundColor={"#E5EAF0"}
              >
                <NumberInputField
                  border={"none"}
                  {...register("en_saving")}
                  variant={"filled"}
                  name="en_saving"
                  placeholder="Energy Saving ( % )"
                />
              </NumberInput>
            </Box>
            <Input
              {...register("attribute")}
              variant={"filled"}
              name="attribute"
              display={"none"}
              placeholder="Attribute"
            />

            {Attr.map((data, i) => (
              <Box
                key={i}
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={"1rem"}
              >
                <Text w={"10%"}>
                  {i === 0 ? "그린 리모델링 적용 기술" : ""}
                </Text>
                <InputGroup w={"90%"}>
                  <Input
                    {...register("attribute")}
                    variant={"filled"}
                    name="attribute"
                    display={"none"}
                    placeholder="Attribute"
                  />
                  <Select
                    onChange={(t) => {
                      return setAttr((p) => {
                        p.splice(i, 1, {
                          ...p[i],
                          trait_type: t.target.value,
                        });
                        return p;
                      });
                    }}
                    variant={"filled"}
                    mr={"2"}
                  >
                    <option value="패시브">패시브</option>
                    <option value="액티브">액티브</option>
                    <option value="신재생">신재생</option>
                    <option value="기타">기타</option>
                  </Select>
                  <Input
                    onChange={(t) =>
                      setAttr((p) => {
                        p.splice(i, 1, {
                          ...p[i],
                          value: t.target.value,
                        });
                        return p;
                      })
                    }
                    variant={"filled"}
                  />
                </InputGroup>
              </Box>
            ))}
            <Box
              w="100%"
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Box mb={"0.5rem"} mr={"0.5rem"} textAlign={"center"}>
                Attribute 추가
              </Box>
              <Button
                onClick={PlusAttribute}
                border={"1px solid black"}
                mr={"0.5rem"}
                p={"0"}
              >
                <AiOutlinePlus width={"100%"} fontSize={"1rem"} />
              </Button>
              <Button
                onClick={MinusAttribute}
                border={"1px solid black"}
                p={"0"}
              >
                <AiOutlineMinus width={"100%"} fontSize={"1rem"} />
              </Button>
            </Box>
          </Box>
          <BuildFileUploadModal
            user={user}
            isOpen={isFileImgOpen}
            onClose={onFileImgClose}
            setValue={setValue}
            header={"Input Build Img"}
          />
          <ModelingUploadModal
            user={user}
            isOpen={isModelingOpen}
            onClose={onModelingClose}
            setValue={setValue}
            header={"Input Modeling File"}
          />
          <RevitUploadModal
            user={user}
            isOpen={isrevitOpen}
            onClose={onrevitClose}
            setValue={setValue}
            header={"Input ravit File"}
          />
          <AddressModal
            setValue={setValue}
            isOpen={isAddressOpen}
            onClose={isAddressClose}
          />
          <DateModal
            setValue={setValue}
            isOpen={isDateOpen}
            onClose={isDateClose}
          />
          <Button type="submit" alignSelf={"flex-end"}>
            저장
          </Button>
        </Box>
      </Box>
    </ProtectedPage>
  );
}
