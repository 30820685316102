import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BuildHeader from "./mybuilding/BuildHeader";
import { Box, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import BuildingTransfer from "./BuildingTransfer";

export default function Root({ children }) {
  const { pathname } = useLocation();

  return (
    <Box position={"relative"} minH={pathname === "/" ? "100vh" : "90vh"}>
      {pathname === "/" ? null : <BuildHeader />}
      {children ? children : <Outlet />}
      <BuildingTransfer />
    </Box>
  );
}
