import { Heading, Spinner, Text, useToast, VStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { kakaoLogin } from "../libs/api";

export default function KakaoConfirm() {
  const { search } = useLocation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const confirmLogin = async () => {
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code) {
      const data = await kakaoLogin(code);
      if (data.code_id === 2003) {
        toast({
          status: "success",
          title: "Welcome!",
          description: "Happy to have you back!",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/buildings");
      } else if (data.code_id === 2006) {
        toast({
          status: "success",
          title: "Welcome! Your First Log In",
          description: "비밀번호를 설정 해주세요.",
        });
        queryClient.refetchQueries(["me"]);
        navigate("/change-password");
      }
    }
  };
  useEffect(() => {
    confirmLogin();
  }, []);
  return (
    <VStack justifyContent={"center"} minH="100vh">
      <Heading>Processing log in...</Heading>
      <Text>Don`t go anywhere.</Text>
      <Spinner size="lg" />
    </VStack>
  );
}
