import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UpdateAvatar } from "../../libs/api";
import { IsOver2MB } from "../../libs/format";

export default function BuildFileUploadModal({
  user,
  isOpen,
  onClose,
  setValue,
  isAvatar = false,
  header = "Update Avatar Img",
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      file: "",
    },
  });
  const toast = useToast();
  const queryClient = useQueryClient();

  const [filePreview, setFilePreview] = useState(isAvatar ? user.avatar : "");
  const file = watch("file");
  useEffect(() => {
    if (file && file.length > 0) {
      const InputFile = file[0];
      const fileExt = InputFile.name.split(".").pop();
      const isOver = IsOver2MB(file[0].size, 5);

      if (
        InputFile.type === "image/jpeg" ||
        fileExt === "jpg" ||
        InputFile.type === "image/png" ||
        fileExt === "png"
      ) {
        if (isOver) {
          setFilePreview(URL.createObjectURL(InputFile));
        } else {
          toast({
            title: "파일 크기가 5mb 보다 큽니다.",
            status: "error",
          });
          reset();
          setFilePreview(isAvatar ? user.avatar : "");
        }
      } else {
        toast({
          title: "jpg 또는 png 파일만 가능합니다.",
          status: "error",
        });
        reset();
        return;
      }
    }
  }, [file, toast, user?.avatar]);

  const onSubmitBuildFile = (file) => {
    if (file !== "") {
      setValue("buildImg", file);
    }

    onClose();
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            mb={"1rem"}
          >
            <Text w={"10%"}>{isAvatar ? "프로필" : ""}</Text>
            <Image
              width={"256px"}
              height={"256px"}
              src={filePreview !== "" ? filePreview : "/image/file_preview.png"}
            />
          </Box>
          <Box
            w={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            mb={"1rem"}
          >
            <Text w={"10%"}></Text>
            <InputGroup
              w={"90%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                type="file"
                {...register("file", {
                  required: { value: true, message: "please input Img" },
                })}
                filename={
                  isAvatar
                    ? user?.avatar ?? "/image/file_preview.png"
                    : "/image/file_preview.png"
                }
                accept="image/png, image/jpeg"
                name="file"
                placeholder="file"
              />
            </InputGroup>
          </Box>
          <Button
            onClick={() => onSubmitBuildFile(file)}
            colorScheme={"red"}
            w="100%"
          >
            Update
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
