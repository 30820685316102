import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <VStack bg="gray.100" justifyContent={"center"} minH="100vh">
      <Heading>404 NotFound</Heading>
      <Text>404 NotFound</Text>
      <Link to="/buildings">
        <Button colorScheme={"blackAlpha"}>GO Home &rarr;</Button>
      </Link>
    </VStack>
  );
}
