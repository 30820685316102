import { GlobalStyles } from "./styles";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { ChakraProvider } from "@chakra-ui/react";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const client = new QueryClient();

  return (
    <>
      <QueryClientProvider client={client}>
        <ChakraProvider>
          <RecoilRoot>
            <RouterProvider router={router} />
            <GlobalStyles />
          </RecoilRoot>
        </ChakraProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
