import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaLock, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { NormalSignUp, usernameLogin, ConfirmEmail } from "../../libs/api";
import { isSignUpFormState } from "../../libs/atoms";
import { emailRegEx } from "../../libs/regExp";

export default function SignUpForm() {
  const [isSignUpForm, setSignUpForm] = useRecoilState(isSignUpFormState);
  const [emailVerify, setEmailVerify] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm();

  const toast = useToast();

  const mutation = useMutation(NormalSignUp, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2001) {
        toast({
          title: "가입이 완료 되었습니다.",
          description: "가입한 메일로 돌아가 인증 후 로그인 해주세요.",
          status: "success",
        });
        navigate("/");
        setSignUpForm(false);
        reset();
      } else if (data.code_id === 3005) {
        toast({
          title: data.msg,
          status: "error",
        });
        resetField("repassword");
      } else if (data.code_id === 3004) {
        toast({
          title: data.msg,
          status: "error",
        });
        resetField("password");
        resetField("repassword");
      }
    },
    onError: (error) => {
      console.log("mutation has an error");
    },
  });

  const Verifymutation = useMutation(ConfirmEmail, {
    onMutate: () => {},
    onSuccess: (data) => {
      if (data.code_id === 2005) {
        toast({
          title: "사용 할 수 있는 이메일 입니다.",
          status: "success",
        });
        setEmailVerify(true);
      } else {
        toast({
          title: "이미 가입된 이메일 입니다.",
          status: "error",
        });
        reset();
      }
    },
    onError: (error) => {
      console.log("사용 할 수 없는 이메일 입니다.");
    },
  });

  const onSubmit = ({ email, name, password, repassword }) => {
    mutation.mutate({
      email,
      name,
      password,
      repassword,
    });
  };

  const onVerifyEmail = ({ email }) => {
    if (emailVerify) {
      setEmailVerify((p) => !p);
    } else {
      if (email !== "") {
        Verifymutation.mutate({ email });
      }
    }
  };

  return (
    <Box width={"90%"} as="form" onSubmit={handleSubmit(onSubmit)}>
      <InputGroup mb={"1rem"}>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaUser />
            </Box>
          }
        />
        <Input
          isDisabled={emailVerify}
          {...register("email", {
            required: { value: true, message: "please write email" },
            pattern: { value: emailRegEx, message: "Email is not valid." },
          })}
          variant={"filled"}
          name="email"
          placeholder="email"
        />
        <InputRightElement
          children={
            <Box
              onClick={() => onVerifyEmail({ email: watch().email })}
              border={"1px solid black"}
            >
              <Button>{emailVerify ? "이메일 변경" : "이메일 확인"}</Button>
            </Box>
          }
        />
      </InputGroup>

      <InputGroup mb={"1rem"}>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaUser />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.name?.message)}
          required
          {...register("name", {
            required: "please write username",
          })}
          variant={"filled"}
          name="name"
          placeholder="username"
        />
      </InputGroup>

      <InputGroup mb={"1rem"}>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaLock />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.password?.message)}
          required
          type="password"
          {...register("password", {
            required: "please write password",
          })}
          name="password"
          variant={"filled"}
          placeholder="Password"
        />
      </InputGroup>

      <InputGroup mb={"1rem"}>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaLock />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.repassword?.message)}
          required
          type="password"
          {...register("repassword", {
            required: "please write password",
          })}
          name="repassword"
          variant={"filled"}
          placeholder="rePassword"
        />
      </InputGroup>

      <Button
        border={"1px solid black"}
        w={"100%"}
        minH={"50px"}
        colorScheme={"gray"}
        isActive={!emailVerify}
        isLoading={mutation.isLoading}
        type="submit"
        mt={4}
      >
        Sign Up
      </Button>
    </Box>
  );
}
