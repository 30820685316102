import {
  Box,
  Heading,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  isAllcomplete,
  isBuildTransfer,
  isModelTransfer,
  isRevitTransfer,
} from "../libs/atoms";

export default function BuildingTransfer() {
  const [BuildTransfer, setBuildTransfer] = useRecoilState(isBuildTransfer);
  const [ModelTransfer, setModelTransfer] = useRecoilState(isModelTransfer);
  const [revitTransfer, setrevitTransfer] = useRecoilState(isRevitTransfer);
  const [Compdata, setCompdata] = useRecoilState(isAllcomplete);
  const [AllComp, setAllComp] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  let ProcessBuildPer = Math.round(
    (BuildTransfer.sendfile / BuildTransfer.filesize) * 100
  );
  let ProcessrevitPer = Math.round(
    (revitTransfer.sendfile / revitTransfer.filesize) * 100
  );
  let ProcessModelPer = Math.round(
    (ModelTransfer.sendfile / ModelTransfer.filesize) * 100
  );

  if (isNaN(ProcessBuildPer)) ProcessBuildPer = "complete";
  if (isNaN(ProcessrevitPer)) ProcessrevitPer = "complete";
  if (isNaN(ProcessModelPer)) ProcessModelPer = "complete";
  useEffect(() => {
    if (
      BuildTransfer.complete &&
      ModelTransfer.complete &&
      revitTransfer.complete
    ) {
      setAllComp(true);
      if (Compdata.code_id === 2011) {
        toast({
          title: "빌드 성공",
          status: "success",
        });
        setCompdata({ ...Compdata, code_id: 0, msg: "" });
        navigate("/buildings");
      } else if (Compdata.code_id === 2012) {
        toast({
          title: "업데이트 성공",
          status: "success",
        });
        setCompdata({ ...Compdata, code_id: 0, msg: "" });
        navigate("/buildings");
      } else if (Compdata.code_id !== 0) {
        toast({
          title: "빌드 실패",
          status: "error",
        });
      }
    } else {
      setAllComp(false);
    }
  }, [setCompdata, Compdata, BuildTransfer, ModelTransfer, revitTransfer]);

  return (
    <VStack
      display={AllComp ? "none" : "flex"}
      position={"absolute"}
      top="0"
      justifyContent={"center"}
      minH="calc(100%)"
      width={"100%"}
      backgroundColor={"white"}
      opacity={"0.5"}
    >
      <VStack opacity={"2"} justifyContent={"center"}>
        <Heading>File Sending...</Heading>
        <Text>Build :{ProcessBuildPer}</Text>
        <Text>revit : {ProcessrevitPer}</Text>
        <Text>Model :{ProcessModelPer}</Text>
        <Spinner size="lg" />
      </VStack>
    </VStack>
  );
}
