import { FaAirbnb, FaMoon, FaSun } from "react-icons/fa";
import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  LightMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import media from "../../libs/media";
import useUser from "../../libs/useUser";
import { useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logOut } from "../../libs/api";

export default function BuildHeader() {
  const { userLoading, isLoggedIn, user } = useUser();
  const { pathname } = useLocation();

  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef();
  const mutation = useMutation(logOut, {
    onMutate: () => {
      toastId.current = toast({
        title: "Log out...",
        description: "Sad to see you go...",
        status: "loading",
        position: "bottom-right",
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries(["me"]);
        toast.update(toastId.current, {
          status: "success",
          title: "Done!",
          description: "See you later!",
        });
        navigate("/");
      }
    },
  });
  const onLogOut = async () => {
    mutation.mutate();
  };
  const navigate = useNavigate();
  const goMain = () => {
    window.open("https://yugy.org/");
  };
  const onClientOpen = () => {
    console.log(pathname);
    navigate("/unitylogin");
    if (pathname === "/unitylogin") {
      window.open(`YugyNowMetaverseFile.myp://${user?.name}`, "_blank");
    }
  };
  return (
    <Stack
      position={"absolute"}
      alignItems={"center"}
      justifyContent={"space-between"}
      top={0}
      minH={"5vh"}
      minW={"100%"}
      bg={"#EAEDDC"}
      direction={"row"}
      px={8}
      borderBottomWidth={1}
    >
      <HStack
        display={"flex"}
        w={"40%"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <Image
          onClick={goMain}
          cursor={"pointer"}
          src="/image/header_logo.png"
          width={["auto", "213px"]}
          maxW={"213px"}
          my={4}
          height={["8vh", "57px"]}
        />
        <Box w={"40%"} display={"flex"} justifyContent={"space-between"}>
          <Link to={"/buildings"}>
            <Text fontWeight={pathname === "/buildings" ? 700 : 500}>
              나의 건설 리스트
            </Text>
          </Link>
          <Link to={"/buildings/new"}>
            <Text fontWeight={pathname === "/buildings/new" ? 700 : 500}>
              새 건설
            </Text>
          </Link>
        </Box>
      </HStack>
      <HStack display={"flex"} flexDirection={"row"} spacing={2}>
        {!userLoading ? (
          <>
            <Button
              backgroundColor={"#77B652"}
              color={"white"}
              onClick={onClientOpen}
            >
              메타버스 바로가기
            </Button>
            <Text fontWeight={pathname === "/profile" ? 700 : 500}>
              {user?.email !== "" ? user?.email : user?.name}
            </Text>
            <Menu>
              <MenuButton>
                <Avatar name={user?.name} src={user?.avatar} size={"md"} />
              </MenuButton>
              <MenuList>
                {user?.is_host ? null : null}
                <Link to="/profile">
                  <MenuItem>User Info</MenuItem>{" "}
                </Link>
                <MenuItem onClick={onLogOut}>Log out</MenuItem>
              </MenuList>
            </Menu>
          </>
        ) : null}
      </HStack>
    </Stack>
  );
}
